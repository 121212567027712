import http from "../http-common";

class MemberListService {
  getList(data) {
    return http.post(`/member/list/`, data);
  }

  getSearchName(data) {
    return http.post(`/member/searchname/`, data);
  }

  register(data) {
    return http.post(`/member/register/`, data);
  }
  
  update(data) {
    return http.post(`/member/update/`, data);
  }
 
  // update(data) {
  //   return http.post(`/manage/update/`, data);
  // }  
}

export default new MemberListService();
