import http from "../http-common";

class NoticeService {
  getList(data) {
    return http.post(`/notice/list`, data);
  }   

  setReadDone(data) {
    return http.post(`/notice/readupdate`, data);
  }   
}

export default new NoticeService();
